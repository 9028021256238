<template>
<div class="conOfSpinner">
    <div class="spinner">

    <div class="spinner-component yelloColor">
    </div>

    <div class="spinner-component yelloColorSec">
    </div>

    <div class="spinner-component yelloColor">
    </div>

    </div>
</div>

</template>
<script>
export default {
    
}
</script>
<style lang="scss">
.conOfSpinner {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index:1;
    left: 0;
    top: 0;
    background: #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    * {
  box-sizing: border-box;
  margin: 0;
}

.spinner {
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position: relative;
  z-index: 10;
}


.spinner-component {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid transparent;
  mix-blend-mode: hue;
}

.yelloColor {
  border-top: 10px solid #360032;
  animation: load 1300ms linear infinite;
  z-index: -1;
}

.yelloColorSec {
  border-top: 10px solid #A1D683;
  animation: load 1600ms ease-out infinite;
  z-index: -3;
}


@keyframes load {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes opaque {
  0%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.1;
  }
}
}
</style>
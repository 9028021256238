<template>
 

    <section class="sectionhome "    >
      
  <!--     <section class="item sectionVideo">
                 <Loader v-if="loader" />
            <img id="mute" :class="[mute ? 'dp-none' : 'dp-block']"  src="@/assets/sound.png" alt="img" @click="toggleMute" />
            <img id="unmute" :class="[mute ? 'dp-block' : 'dp-none']"  src="@/assets/no sound.png" alt="img" @click="toggleunMute" />

   
        <video class="videoStyle d-none d-sm-block " playsinline autoplay :muted="mute" loop>

    <source src="@/assets/video1.mp4"
            type="video/mp4">

    Sorry, your browser doesn't support embedded videos.
</video>

        <video  class=" videoStyle2 d-block d-sm-none" playsinline  autoplay :muted="mute" loop>
             <source src="@/assets/video2.mp4" 
            type="video/mp4">

    Sorry, your browser doesn't support embedded videos.
</video>
      </section> -->
        <section class="slider-item">
         <Loader v-if="loader" />
                         <img v-bind:class="$i18n.locale === 'ar' ?' logoImgAr' : 'logoImg'"  src="@/assets/woroud-logo.svg" alt="logo" @click="$router.push({path:'home'})" />

          <v-carousel
        
          :continuous="true"
      :show-arrows="false"
      cycle
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="100vh">
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        eager
      >
        <v-img   v-bind:class="$i18n.locale === 'ar' ?' flip' : ''" :src="item.src" height="100%" eager/>
         
      </v-carousel-item>
    <div v-bind:class="$i18n.locale === 'ar' ?'slider-txtAr' : 'slider-txt'">
       {{ $t('DiscoverThefuture')}}
  
    </div>
         <v-btn v-bind:class="$i18n.locale === 'ar' ? ' discover-btnAr' : 'discover-btn'" @click="$router.push({name:'WhoWeAre'})">
            {{ $t('Discover')}}

          </v-btn>
    </v-carousel>
         <!--   <v-carousel
      :continuous="true"
      :show-arrows="false"
      cycle
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="100vh"
     
    >
      <v-carousel-item
           v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      class="cara" 
      >
      
  <v-row class="fill-height" align="center" justify="center">
        <div class="display-3 white--text">           
             <div class="worodTxt d-flex align-center justify-center"> WOROUD LINE</div>
            <div class="arcTxt d-flex align-center justify-center"> ARCHITECTURE</div>
                        <div class="arcTxt d-flex align-center justify-center">INNOVATIVE</div>
                              <div class="designTxt d-flex align-center justify-center">DESIGN</div>
              <div class="worodTxt d-flex align-center justify-center">  <v-icon style="color: #F167A7;"
          dark
         
        >
          mdi-minus
        </v-icon> <span @click="$router.push({path:'/about'})">{{ $t('Read more')}} </span></div>



         </div>
          </v-row> 
         <v-btn class="discover-btn"  @click="$router.push({path:'/about'})">
            {{ $t('Discover')}}

          </v-btn>
      </v-carousel-item>
    </v-carousel> --> 
        </section>
    
   <div class="pos-relative">
       
          <section class="sectiontwo item" >

         <v-container>

        <v-row class=" d-flex justify-center align-center mt-2">
            <v-col  cols="12" md="5" class="d-flex align-center justify-center" data-aos="slide-right"

    data-aos-delay="50"
    data-aos-duration="1000"
  
 
    
    >
                                <img class="img-style" src="@/assets/story.png" alt="img" v-bind:class="$i18n.locale === 'ar' ?' flip' : ''" />


          </v-col>
            <v-col  cols="12" md="6" class=" conofLineImg" data-aos="slide-left"

    data-aos-delay="50"
    data-aos-duration="1000">
              <v-row class="conOfBrandstory">
               <v-col cols="12" class="d-flex align-center  pb-0 pt-0">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" > {{ $t('story')}}
                 </div>
            </v-col>
            <v-col cols="12" class="pt-0">
                       <div>{{ $t('story-txt')}} </div>
            </v-col>
            <v-col cols="12" class=" pb-0 pt-0" >
             <v-btn class="discover-btn02" v-bind:class="{'ar-font':($i18n.locale === 'ar')}"  @click="$router.push({name:'WhoWeAre'})">
            {{ $t('Discover')}}

          </v-btn>
            </v-col>
              </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
    
      <section class="sectionActivites sectionthree item">

      <v-container>
        <v-row class="d-none d-md-flex">
          <v-col md="4" cols="12"  data-aos="flip-down"

    data-aos-delay="100"
    data-aos-duration="1000">
           <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('Al Woroud Line Areas')}} </div>

          </v-col>
           <v-col md="8" cols="12"  data-aos="flip-down"

    data-aos-delay="100"
    data-aos-duration="1000">
                     <div> {{ $t('Discover the heritage-2')}}</div>
          </v-col>
          <v-col md="3" cols="12" class="pr-0 pl-0 conofActivityImg" @click="$router.push({path:'areas'  , query: { id: '1' }})" data-aos="flip-right"

    data-aos-delay="210"
    data-aos-duration="2000">
          <img class="area-img" src="@/assets/home01.png" alt="img" />
         
            <span v-bind:class="$i18n.locale === 'ar' ?' activitytxtAr' : ' activitytxtEn'" >
{{ $t('area-txt1')}}   </span>

          </v-col>
          <v-col md="3" cols="12"  class="pl-0 pr-0 conofActivityImg" @click="$router.push({path:'areas'  , query: { id: '2' }})" data-aos="flip-right"

    data-aos-delay="220"
    data-aos-duration="2000">
                      <img class="area-img" src="@/assets/home02.png" alt="img" />
    
          <span v-bind:class="$i18n.locale === 'ar' ?' activitytxtAr' : ' activitytxtEn'" >
           {{ $t('area-txt2')}}
          </span>
          </v-col>
          <v-col md="3" cols="12" class="pl-0 pr-0 conofActivityImg" @click="$router.push({path:'areas'  , query: { id: '3' }})" data-aos="flip-right"

    data-aos-delay="230"
    data-aos-duration="2000">
                      <img class="area-img" src="@/assets/home03.png" alt="img" />
  
          <span v-bind:class="$i18n.locale === 'ar' ?' activitytxtAr' : ' activitytxtEn'" >
{{ $t('area-txt3')}}        </span>
          </v-col>
                   <v-col md="3" cols="12" class="pl-0 pr-0 conofActivityImg" @click="$router.push({path:'areas'  , query: { id: '4' }})" data-aos="flip-right"

    data-aos-delay="240"
    data-aos-duration="2000">
                      <img class="area-img" src="@/assets/home04.png" alt="img" />

          <span v-bind:class="$i18n.locale === 'ar' ?' activitytxtAr' : ' activitytxtEn'" >
{{ $t('area-txt4')}}        </span>
          </v-col>
        </v-row>
       
        <v-row class="d-md-none d-flex">
               <v-col md="4" cols="12"  data-aos="flip-down"

    data-aos-delay="100"
    data-aos-duration="1000">
           <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('Al Woroud Line Areas')}} </div>

          </v-col>
           <v-col md="8" cols="12"  data-aos="flip-down"

    data-aos-delay="100"
    data-aos-duration="1000">
                     <div> {{ $t('Discover the heritage-2')}}</div>
          </v-col>
         <v-carousel
          :continuous="true"
      :show-arrows="true"
      cycle
      hide-delimiter-background
      hide-delimiters
      >
      <v-carousel-item
        v-for="(item,i) in mobSlider"
        :key="i"
        eager
        class="pos-relative"
          @click="$router.push({path:'areas'  , query: { id: i+1 }})"
      >
 
         <v-row
            class="fill-height"
            align="center"
            justify="start"
          >
                 <v-img :src="item.src" height="100%" eager />
            <div class="mobSlider-txt">
             {{ $i18n.locale === 'ar' ? item.text.Ar : item.text.En}}
            </div>
          </v-row>
      </v-carousel-item>
      </v-carousel>
        </v-row>
          </v-container>
    </section>
   </div>
   <section class="sectionVirtual pos-relative "  >
     <div class="virtual-pattern" v-bind:class="$i18n.locale === 'ar' ?' flip' : ''">
     </div>
     <div class="virtualbg">
     </div>
              <v-carousel
          :continuous="true"
      :show-arrows="false"
      cycle
      hide-delimiter-background
      hide-delimiters
   class="d-none d-md-block"
      height="450">
      <v-carousel-item
        v-for="(item,i) in virtual"
        :key="i"
        eager
      
      >
        <v-img :src="item.src" height="100%" eager />
         
      </v-carousel-item>
     <div   v-bind:class="$i18n.locale === 'ar' ?' conOfSlider2Ar' : ' conOfSlider2En'">
        <div class="slider-head2 " data-aos="flip-right"

    data-aos-delay="230"
    data-aos-duration="2000">
         {{ $t('virtual')}}
      </div>
    <div class="slider-txt2 mb-3" data-aos="flip-right"

    data-aos-delay="230"
    data-aos-duration="2000">
     {{ $t('new.caption')}}
     {{ $t('new.caption')}}
    </div>
         <v-btn class="try-btn" data-aos="flip-right"

    data-aos-delay="230"
    data-aos-duration="2000">
            {{ $t('try')}}

          </v-btn>
     </div>
    </v-carousel>
      <v-carousel
          :continuous="true"
      :show-arrows="false"
      cycle
      hide-delimiter-background
      hide-delimiters
   class="d-block d-md-none"
      height="800">
      <v-carousel-item
        v-for="(item,i) in virtual"
        :key="i"
        eager
      
      >
        <v-img :src="item.src" height="100%" eager />
         
      </v-carousel-item>
     <div   v-bind:class="$i18n.locale === 'ar' ?' conOfSlider2Ar' : ' conOfSlider2En'">
        <div class="slider-head2 " data-aos="flip-right"

    data-aos-delay="230"
    data-aos-duration="2000">
         {{ $t('virtual')}}
      </div>
    <div class="slider-txt2 mb-3" data-aos="flip-right"

    data-aos-delay="230"
    data-aos-duration="2000">
     {{ $t('new.caption')}}
     {{ $t('new.caption')}}
    </div>
         <v-btn class="try-btn" data-aos="flip-right"

    data-aos-delay="230"
    data-aos-duration="2000">
            {{ $t('try')}}

          </v-btn>
     </div>
    </v-carousel>
   </section>
      <section class="sectionNews sectionfour item">

      <v-container>
        <v-row >
           <v-col cols="12">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('Latest News & Events')}} </div>
            </v-col>
          <v-col  cols="12" class="py-2 px-2">
              <v-carousel
      :continuous="true"
      :show-arrows="true"
      hide-delimiters
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="300px"
      class="pos-relative d-none d-md-flex"
      v-bind:class="$i18n.locale === 'ar' ?' flip' : ''"
    >
      <template v-slot:prev="{ on, attrs }">
      <v-btn
          style="border-radius: 0 !important; min-width: 44px!important;"
        color="#EDF0F2"
        v-bind="attrs"
        v-on="on"
      >                       <img class="img-fluid" src="@/assets/black_arrow.png" alt="img" />
</v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
         style="border-radius: 0 !important; min-width: 44px!important;"
        color="#A1D683"
        v-bind="attrs"
        v-on="on"
      >  <img class="img-fluid" src="@/assets/white_arrow.png" alt="img" /></v-btn>
    </template>
      <v-carousel-item
           v-for="(item,i) in newsImg"
      :key="i"
      :src="item.src"
      class="cara" 
    
      >
      
   <v-row class="fill-height  " >
        <div class="display-3 white--text "  v-bind:class="$i18n.locale === 'ar' ?' conOfTxtAr' : ' conOfTxt'" >           
     <div  v-bind:class="$i18n.locale === 'ar' ?' TxtAr' : 'ltr'"  >
          <div class="d-flex " >
             <div class="white--text dayTxt"> 13</div>
            <div class="monthTxt ">     {{ $t('October')}}
              <div class="percentageTxt">50% </div><div class="monthTxt ">    {{ $t(' DISCOUNT')}}</div>
            </div>
        </div>
           <div class="slider-news-sub">
     {{ $t('new.caption')}}
     <div><router-link :to="{name: 'news-item', params: { lang: $i18n.locale , id:1}}"  v-bind:class="$i18n.locale === 'ar' ?'ar-font moreAr' : ' more'"> {{ $t('Read More')}}
                             <img class="arrow-img"  v-bind:class="{'ltr swap-arrow':($i18n.locale === 'ar')}" src="@/assets/arrow.png" alt="img" />

               </router-link></div>

     </div>
    </div>              
 

         </div>
          </v-row>
       
      </v-carousel-item>
    </v-carousel>
        <v-carousel
      :continuous="true"
      :show-arrows="false"
      
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="450px"
      class="pos-relative d-flex d-md-none"
      v-bind:class="$i18n.locale === 'ar' ?' flip' : ''"
    >
      <template v-slot:prev="{ on, attrs }">
      <v-btn
          style="border-radius: 0 !important; min-width: 44px!important;"
        color="#EDF0F2"
        v-bind="attrs"
        v-on="on"
      >                       <img class="img-fluid" src="@/assets/black_arrow.png" alt="img" />
</v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
         style="border-radius: 0 !important; min-width: 44px!important;"
        color="#A1D683"
        v-bind="attrs"
        v-on="on"
      >  <img class="img-fluid" src="@/assets/white_arrow.png" alt="img" /></v-btn>
    </template>
      <v-carousel-item
           v-for="(item,i) in newsImg"
      :key="i"
      :src="item.src"
      class="cara" 
    
      >
      
   <v-row class="fill-height align-end ma-0 " >
        <div class="display-3 white--text "  v-bind:class="$i18n.locale === 'ar' ?' conOfTxtAr' : ' conOfTxt'" >           
     <div  v-bind:class="$i18n.locale === 'ar' ?' TxtAr' : 'ltr'"  >
          <div class="d-flex " >
             <div class="white--text dayTxt"> 13</div>
            <div class="monthTxt ">     {{ $t('October')}}
              <div class="percentageTxt">50% </div><div class="monthTxt ">    {{ $t(' DISCOUNT')}}</div>
            </div>
        </div>
           <div class="slider-news-sub">
     {{ $t('new.caption')}}
  

     </div>
   <div><router-link :to="{name: 'news-item', params: { lang: $i18n.locale , id:1}}"  v-bind:class="$i18n.locale === 'ar' ?'ar-font slidermoreAr' : ' slidermore'"> {{ $t('Read More')}}
                             <img class="arrow-img"  v-bind:class="{'ltr swap-arrow':($i18n.locale === 'ar')}" src="@/assets/arrow.png" alt="img" />

               </router-link></div>
    </div>              
 

         </div>
          </v-row>
       
      </v-carousel-item>
    </v-carousel>
          </v-col>
              <v-col md="6" cols="12" class=" pa-5">
                  <v-row :key="neww.date" v-for="neww in news" class="mt-4">
              <v-col sm="6" cols="12" class="pa-0" data-aos="slide-right"

    data-aos-delay="250"
    data-aos-duration="2000">
                       <img class="new-img" :src="neww.src" alt="img" />

            </v-col>
             <v-col sm="6" cols="12" class="pa-0 conOfNews" data-aos="slide-left"

    data-aos-delay="250"
    data-aos-duration="2000">
              <div>
                    <span class="dateTxt" >{{ $t('new.date')}}</span>
              <h2 class="newsHead">{{ $t('new.head')}}</h2>
               <p class="my-0 newsSubhead">{{ $t('new.caption')}}</p>
               <router-link :to="{name: 'news-item', params: { lang: $i18n.locale , id:1}}"  v-bind:class="$i18n.locale === 'ar' ?'ar-font moreAr' : ' more'"> {{ $t('Read More')}}
                             <img class="arrow-img"  v-bind:class="{'ltr swap-arrow':($i18n.locale === 'ar')}" src="@/assets/arrow.png" alt="img" />

               </router-link>
              </div>

            </v-col>
                  </v-row>
            </v-col>
             <v-col md="6" cols="12" class=" pa-5">
                  <v-row :key="neww.date" v-for="neww in news" class="mt-4">
              <v-col sm="6" cols="12" class="pa-0 " data-aos="slide-right"

    data-aos-delay="250"
    data-aos-duration="2000">
                       <img class="new-img" :src="neww.src" alt="img" />

            </v-col>
             <v-col sm="6" cols="12" class="pa-0 conOfNews" data-aos="slide-left"

    data-aos-delay="250"
    data-aos-duration="2000" >
              <div>
                    <span class="dateTxt" >{{ $t('new.date')}}</span>
              <h2 class="newsHead">{{ $t('new.head')}}</h2>
               <p class="my-0 newsSubhead">{{ $t('new.caption')}}</p>
               <router-link :to="{name: 'news-item', params: { lang: $i18n.locale , id:1}}" v-bind:class="$i18n.locale === 'ar' ?'ar-font moreAr' : ' more'" > {{ $t('Read More')}}
                             <img class="arrow-img"  v-bind:class="{'ltr swap-arrow':($i18n.locale === 'ar')}" src="@/assets/arrow.png" alt="img" />

               </router-link>
              </div>

            </v-col>
                  </v-row>
            </v-col>
        </v-row>
        
      </v-container>
    </section>
          <div class="conOfimages ">
              <div class="fill-height ltr TxtAr">
    <CoolLightBox 
      :items="images" 
      :index="index"
      @close="index = null">
     
    </CoolLightBox>

    <div class="images-wrapper d-md-flex d-none" data-aos="slide-right"  data-aos-easing="ease-in"

    data-aos-delay="250"
    data-aos-duration="2000">
     
      <v-row  class="mt-4 mb-4 d-flex align-center justify-center conOfAllImages" >
        <v-col cols="4" class="pa-0">
          <div class="conOfoneimg" >
            <img class="image image1" src="@/assets/img1.jpg" alt="" @click="index = 0">

            <img class="zoom" src="@/assets/zoom.svg">
          </div>
        </v-col>
     
             <v-col cols="4" class="pa-0">
               <div class="conOftwoimg">
            <img class="image image2" src="@/assets/img4.jpg" alt="" @click="index = 1">
            <img class="zoom" src="@/assets/zoom.svg">
               </div>
        <div class="conOftwoimg">
            <img class="image image2" src="@/assets/img3.jpg" alt="" @click="index = 2">
            <img class="zoom" src="@/assets/zoom.svg">
            </div>
             </v-col>
        
         <v-col cols="4" class="pa-0">
           <div class="conOfoneimg">
            <img class="image image1" src="@/assets/img02.jpg" alt="" @click="index = 3">
                        <img class="zoom" src="@/assets/zoom.svg">

           </div>
         </v-col>
      </v-row>
    </div>
               <div class="d-md-none d-flex">
                 <v-carousel
          :continuous="true"
      :show-arrows="true"
      cycle
      hide-delimiter-background
      hide-delimiters
      >
      <v-carousel-item
        v-for="(item,i) in images"
        :key="i"
        eager
    
          @click="index = i"
      >
                  <v-img :src="item" height="100%" eager />

      </v-carousel-item>
      </v-carousel>
              </div> 
  </div>
       
    </div>

        </section>
 

</template>
<script>

import Loader from "@/modules/shared/components/loader"


  export default {
       

     components: {
    Loader,
   
    
   
    },

    data () {
      return {
        loader:false,
        mobile:true,
        mute: true,
         index: null,
         images: [
        require('@/assets/img1.png'),
require('@/assets/img4.png'),
 require('@/assets/img3.png'),
 require('@/assets/img02.png'),
      ],
     
          newsImg: [
          {
            src: require('@/assets/news01.png'),
          },
          {
            src: require('@/assets/news01.png'),
          },
          {
            src: require('@/assets/news01.png'),
          },
          {
            src: require('@/assets/news01.png'),
          },
        ],
        items: [
          {
            src: require('@/assets/Base.png'),
          },
          {
            src: require('@/assets/slide02.jpg'),
          },
          {
            src: require('@/assets/slide03.jpg'),
          },
          
        
        ],
          virtual: [
          {
            src: require('@/assets/mall.png'),
          },
          {
            src: require('@/assets/mall2.jpeg'),
          },
        
        
        ],
            mobSlider: [
          { text:{En:"History & Heritage",Ar:"التاريخ والحضاره "},
            src: require('@/assets/home01.png'),
          },
          { text: {En:"Family & child",Ar:"الاطفال و العائله "},
            src: require('@/assets/home02.png'),
          },
         {   text: {En:"Arts & Educational",Ar:"الفن والتعليم "},
            src: require('@/assets/home03.png'),
          },
           {
              text:{En:"Youth & Technology",Ar:"الشباب والتكنولوجيا "},
            src: require('@/assets/home04.png'),
          },
        
        ],
        news: [
          {
            src: require('@/assets/news02.png'),
            date:'10 OCTOBER, 2021',
            head:'Quis autem vel eum iure',
            caption:'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem.'
          },
     
        ],
              slides: ["Innovation", "Design", "Technology"]

      }
    },
     methods: {
            isMobile() {
                if( screen.width <= 760 ) {
                  this.mobile=false
                  console.log(this.mobile);
                    return true;
                }
                else {
                                    this.mobile=true

                    return false;
                }
            },
              toggleMute() {
      this.mute = !this.mute
    },
          toggleunMute() {
      this.mute = !this.mute
    }
      
            
        }, 


created()
{
  this.isMobile()
}
        
  }
</script>
<style lang="scss" scoped>
@import "./_homepage.scss";
</style>
